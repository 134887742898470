import { createSlice } from '@reduxjs/toolkit'


const initialState = {
  purposalForm: {
    title: "",
    first_name: "",
    last_name: "",
    dob: "",
    gender: "",
    contact_no: "",
    email: "",
    customer_type: "",
    full_address: "",
    pin_code: "",
    district: "",
    state: "",
    rto_code: "",
    reg_no: "",
    engine_no: "",
    chassis_no: "",
    previous_policy_no: "",
    previous_insurer_name: "",
    previous_od_insurer_name: "",
    previous_tp_insurer_name: "",
    previous_od_policy_no: "",
    previous_tp_policy_no: "",
    vehicle_on_loan: "No",
    financy_company_id: "",
    financy_company_name: "",
    nominee_name: "",
    nominee_dob: "",
    nominee_relation: "",
    proof_of_identity: "",
    proof_of_identity_img_front: null,
    proof_of_identity_img_front_path: null,

    proof_of_identity_img_back: "",
    proof_of_identity_img_back_path: null,

    proof_of_address: "",
    proof_of_address_img_front: "",
    proof_of_address_img_front_path: "",

    proof_of_address_img_back: "",
    proof_of_address_img_back_path: "",

    proof_of_rc: "RC",
    proof_of_rc_img_front: "",
    proof_of_rc_img_front_path: "",

    proof_of_rc_img_back: "",
    proof_of_rc_img_back_path: "",

    proof_of_previous_policy: "Previous Policy",
    proof_of_previous_policy_img_front: "",
    proof_of_previous_policy_img_front_path: "",

    proof_of_previous_policy_img_back: "",
    proof_of_previous_policy_img_back_path: "",

    proof_of_photo: "Photo",
    proof_of_photo_img_front: "",
    proof_of_photo_img_front_path: "",

    proof_of_photo_img_back: "",
    proof_of_photo_img_back_path: "",

    other_documents: [],
    selected_cover_options: [],
    policy_type_id:"",
    policy_type_name:"",

  },
  quotationId: "",
  dealId: "",

  quotation: null
}

export const purposalSlice = createSlice({
  name: 'purposal',
  initialState: initialState,
  reducers: {
    setFormValue: (state, action) => {
      let formType = action.payload.formType;
      let key = action.payload.key;
      let value = action.payload.value;
      if (state[formType]) {
        state[formType][key] = value
      }
    },

    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
    setQuotationId: (state, action) => {
      state.quotationId = action.payload
    },
    
    setDealId: (state, action) => {
      state.dealId = action.payload
    },
   

    setQuotation: (state, action) => {
      console.log('action.payload', action.payload)
      state.quotation = action.payload
    },
    emptyQuotation: (state) => {
      state.quotation = null
    },
    emptyQuotationId: (state) => {
      state.quotationId = ""
    },
    emptyDealId: (state) => {
      state.dealId = ""
    },
    emptyPorposalForm: (state) => {
      state.purposalForm = {
        title: "",
        first_name: "",
        last_name: "",
        dob: "",
        gender: "",
        contact_no: "",
        email: "",
        customer_type: "",
        full_address: "",
        pin_code: "",
        district: "",
        state: "",
        rto_code: "",
        reg_no: "",
        engine_no: "",
        chassis_no: "",
        previous_policy_no: "",
        previous_insurer_name: "",
        previous_od_insurer_name: "",
        previous_tp_insurer_name: "",
        previous_od_policy_no: "",
        previous_tp_policy_no: "",
        vehicle_on_loan: "No",
        financy_company_id: "",
        financy_company_name: "",
        nominee_name: "",
        nominee_dob: "",
        nominee_relation: "",
        proof_of_identity: "",
        proof_of_identity_img_front: null,
        proof_of_identity_img_front_path: null,

        proof_of_identity_img_back: "",
        proof_of_identity_img_back_path: null,

        proof_of_address: "",
        proof_of_address_img_front: "",
        proof_of_address_img_front_path: "",

        proof_of_address_img_back: "",
        proof_of_address_img_back_path: "",

        proof_of_rc: "RC",
        proof_of_rc_img_front: "",
        proof_of_rc_img_front_path: "",

        proof_of_rc_img_back: "",
        proof_of_rc_img_back_path: "",

        proof_of_previous_policy: "Previous Policy",
        proof_of_previous_policy_img_front: "",
        proof_of_previous_policy_img_front_path: "",

        proof_of_previous_policy_img_back: "",
        proof_of_previous_policy_img_back_path: "",

        proof_of_photo: "Photo",
        proof_of_photo_img_front: "",
        proof_of_photo_img_front_path: "",

        proof_of_photo_img_back: "",
        proof_of_photo_img_back_path: "",
        other_documents: [],
        selected_cover_options: [],
        policy_type_id:"",
        policy_type_name:"",
      }
    },
    addSelectedCoverOption: (state, action) => {
      console.log('action.payload',action.payload)
      state.purposalForm.selected_cover_options = [...state.purposalForm.selected_cover_options,action.payload]
    },
    removeSelectedCoverOption: (state, action) => {
       let tmpState = state.purposalForm.selected_cover_options.filter((item) => item.title != action.payload.title)
       state.purposalForm.selected_cover_options = [...tmpState]
    },

    setSelectionCoverInputValue: (state, action) => {
      let otherOptions = state.purposalForm.selected_cover_options.filter((item) => item.title != action.payload.title)
      let currentOption = state.purposalForm.selected_cover_options.find((item) => item.title == action.payload.title)
      currentOption['inputValue'] = action.payload.amount;
 
      state.purposalForm.selected_cover_options = [...otherOptions,currentOption]
    },
    setSelectionCoverDropdownValue: (state, action) => {
      let otherOptions = state.purposalForm.selected_cover_options.filter((item) => item.title != action.payload.title)
      let currentOption = state.purposalForm.selected_cover_options.find((item) => item.title == action.payload.title)
      currentOption['dropdownValue'] = action.payload.value;
 
      state.purposalForm.selected_cover_options = [...otherOptions,currentOption]
    },

    emptySelectedCoverOption: (state, action) => {
       state.purposalForm.selected_cover_options = []
    },

  },
})

export const { increment, decrement, incrementByAmount, setFormValue, setQuotationId, setQuotation, emptyQuotation, emptyQuotationId, emptyPorposalForm,addSelectedCoverOption,removeSelectedCoverOption,setSelectionCoverInputValue,setSelectionCoverDropdownValue,emptySelectedCoverOption,setDealId,emptyDealId } = purposalSlice.actions




// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const incrementAsync = (amount) => (dispatch) => {
  setTimeout(() => {
    dispatch(incrementByAmount(amount))
  }, 1000)
}



// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export default purposalSlice.reducer
