import React, { Component, Suspense } from 'react'
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { Provider } from 'react-redux'
// import store from './store'



const loading = (
  <div className="pt-3 text-center" >
    <div className="sk-spinner sk-spinner-pulse" ></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const SuperAdminLogin = React.lazy(() => import('./views/superadmin/login/Login'))
const BrokerLogin = React.lazy(() => import('./views/broker/login/Login'))
const BrokerRegister = React.lazy(() => import('./views/broker/register/Register'))

const AgentLogin = React.lazy(() => import('./views/agent/login/Login'))
const EmployeeLogin = React.lazy(() => import('./views/employee/login/Login'))






const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))




class App extends Component {
  render() {
    return (

      <HashRouter>
        <Suspense
          fallback={loading}
        >
          <Routes>

            {/* <Route exact path="/admin/login" name="Login Page" element={<SuperAdminLogin />} />
           <Route exact path="/broker/login" name="Login Page" element={<BrokerLogin />} />
           <Route exact path="/agent/login" name="Login Page" element={<AgentLogin />} />
           <Route exact path="/employee/login" name="Login Page" element={<EmployeeLogin />} />
           <Route exact path="/broker/register" name="Register Page" element={<BrokerRegister />} /> */}





            <Route exact path="/login" name="Login Page" element={<AgentLogin />} />

            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>

    )
  }
}

export default App
